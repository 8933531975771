// ChatArea.js
import React from 'react';
import ChatBubble from './ChatBubble';

const ChatArea = ({ messages, gptImgLogo, userIcon, msgEnd }) => {
  return (
    <div className="chats">
        {/* Mapping through messages to display chat bubbles */}
        {messages.map((message, i) =>
            <ChatBubble key={i} message={message.text} isBot={message.isBot} avatar={message.isBot? gptImgLogo : userIcon} />
        )}
        {/* Reference for scrolling to the bottom */}
        <div ref={msgEnd} />
    </div>

  );
};




  

export default ChatArea;
