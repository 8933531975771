// ChatBubble.js
import React from 'react';
import gptImgLogo from './../assets/chatgptLogo.svg';

const ChatBubble = ({ message, isBot, avatar }) => {
  return (
    <div className={isBot ? "chat bot" : "chat"}>
      <img className='chatImg' src={isBot ? gptImgLogo : avatar} alt="AV" />
      <p className="txt">{message}</p>
    </div>
  );
};

export default ChatBubble;
