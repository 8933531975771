// Loading.jsx
import React from 'react';
import loadingSpinner from './../assets/dot-animation.svg';

const Loading = () => {
  return (
    <div className="loading">
      <img src={loadingSpinner} alt="Loading" />
    </div>
  );
};

export default Loading;