// Importing necessary styles and assets
import './App.css';
import sendBtn from './assets/send.svg';
import userIcon from './assets/avatar.svg';
import gptImgLogo from './assets/chatgptLogo.svg';
import { useEffect, useRef, useState } from 'react';
import { sendMsgToBackend } from './api';
import Sidebar from './components/Sidebar';
import ChatArea from './components/ChatArea';
import ChatInput from './components/ChatInput';
import NavBar from './components/NavBar';
import Loading from './components/Loading';


// Main App component
function App() {
    // Ref for scrolling to the bottom of the chat
    const msgEnd = useRef(null);

    // State for input and messages
    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([
        {
            text: "Olá, eu sou o Assistente Virtual, especialista em Fiscalidade, IRS, IRC, IVA e muito mais. Em que posso ajudar?",
            isBot: true,
        }
    ]);
    const [loading, setLoading] = useState(false);

    // Effect to scroll to the bottom when messages change
    useEffect(() => {
        msgEnd.current.scrollIntoView();
    }, [messages]);

    // Function to handle sending a message
    const handleSend = async () => {
        const text = input;
        setInput('');
        setMessages([
            ...messages,
            { text, isBot: false }
        ]);
        console.log('GONNA INVOKE API');
        setLoading(true);
        const res = await sendMsgToBackend(text);
        setLoading(false);
        console.log('res', res);
        setMessages([
            ...messages,
            { text, isBot: false },
            { text: res, isBot: true }
        ]);
    }

    // Function to handle Enter key press
    const handleEnter = async (e) => {
        if (e.key === 'Enter') await handleSend();
    }

    // Function to handle user queries
    const handleQuery = async (e) => {
        const text = e.target.value;
        setMessages([
            ...messages,
            { text, isBot: false }
        ]);
        setLoading(true);
        const res = await sendMsgToBackend(text);
        setLoading(false);
        setMessages([
            ...messages,
            { text, isBot: false },
            { text: res, isBot: true }
        ]);
    }

    // JSX structure of the component
    return (
        <div className="App" >
            
          {/* Collapsible Sidebar */}
          <div className="sideBar">
            <Sidebar handleQuery={handleQuery} />
          </div>
    
          {/* Main Chat Section */}
          <div className="main" >
            <div className="navbar" style={{width: '100%'}}>
                <NavBar />
            </div>
    
            {/* Chat Area and Input */}
            <ChatArea messages={messages} gptImgLogo={gptImgLogo} userIcon={userIcon} msgEnd={msgEnd}></ChatArea>
            {loading && <Loading />}
            <ChatInput input={input} handleEnter={handleEnter} setInput={setInput} handleSend={handleSend} sendBtn={sendBtn} ></ChatInput>
          </div>
        </div>
      );
}

// Exporting the App component as the default export
export default App;