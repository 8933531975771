import React from 'react';

const ChatInput = ({ input, handleEnter, setInput, handleSend, sendBtn }) => {
  return (
    <div className="chatFooter">
      <div className="inp">
        <input type="text" placeholder='Send a message' value={input} onKeyDown={handleEnter} onChange={(e) => { setInput(e.target.value) }} />
        <button className="send" onClick={handleSend} ><img src={sendBtn} alt="Send" /></button>
      </div>
      {/* Disclaimer */}
      <p>O Assistente Virtual pode produzir informação incorreta sobre pessoas, locais ou factos. Consulte as fontes para confirmar resultados.</p>
    </div>
  );
}

export default ChatInput;
