// Sidebar.js
import React from 'react';
import gptLogo from './../assets/chatLogo.svg';
import addBtn from './../assets/add-30.png';
import msgIcon from './../assets/message.svg';
import rocket from './../assets/rocket.svg';

const Sidebar = ({ handleQuery }) => {
  return (
    <div>
        <div className="upperSide">
            <div className="upperSideTop">
                <img src={gptLogo} alt="Logo" className="logo" />
                <span className="brand">Assistente Virtual</span>
            </div>
            <button  style={{ width: '25rem', margin: '0 auto' }} className="midBtn" onClick={() => { window.location.reload() }}>
                <img src={addBtn} alt="new chat" className="addBtn" />
                New Chat
            </button>
            <div className="upperSideBottom">
                {/* Example query buttons */}
                <button className="query" onClick={handleQuery} value={"O que é o IRS Jovem?"}>
                    <img src={msgIcon} alt="Query" />
                    O que é o IRS Jovem?
                </button>
                <button className="query" onClick={handleQuery} value={"Quanto vou pagar de IRS do ano 2023?"}>
                    <img src={msgIcon} alt="Query" />
                    Quanto vou pagar de IRS?
                </button>
            </div>
        </div>
        <div className="lowerSide">
            <div className="listItems"><img src={rocket} alt="Upgrade" className="listItemsImg" />Powered by IBM</div>
        </div>
    </div>
  );
};

export default Sidebar;
