import gptLogo from './../assets/chatLogo.svg';
import addBtn from './../assets/add-30.png';

const NavBar = () => {
    return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="upperSideTop">
                <img src={gptLogo} alt="Logo" className="logo" />
                <div >
                    <div className="brand">Assistente Virtual</div>
                    <div>Powered by IBM</div>
                </div>
            </div>
            <button style={{  }} className="midBtn" onClick={() => { window.location.reload() }}>
                <img src={addBtn} alt="new chat" className="addBtn" />
                New Chat
            </button>
            <div>
            
        </div>
        </div>
      );
    
}

export default NavBar;