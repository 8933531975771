const axios = require('axios');

export async function sendMsgToBackend(message, headers = {}) {
    try {
        console.log('sendMsgToBackend', message);
        const data = {
            user_id: 'test',
            message: message
        }
        const response = await axios.post("https://aibots-be.19sgut72q2mx.eu-de.codeengine.appdomain.cloud", data, {headers});
    return response.data.data.response.replace(/^"|"$/g, '').trim();
    } catch (e) {
        throw e;
    }
}
